.info-wrapper {
    min-width: 5em;
    position: relative;
}

.info-icon {
    cursor: pointer;
}

.info-list {
    opacity: 0;
    display: none;
    transition: 0.2s;
    z-index: 99;
}

.info-list-open {
    width: 300px;
    padding: 1em 1em;
    background: #fff;
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
    border-radius: 0.8em;
    color: #000;
    position: absolute;
    top: 1.75em;
    left: 60%;
    transform: translateX(-50%);
    opacity: 1;
    display: block;
    transition: 0.2s;
    z-index: 99;
}

.info-number {
    font-size: 0.8em;
    margin-right: 1em;
    background-color: #cad8e7;
    border-radius: 50%;
    padding: 0.1em 0.3em;
}

.info-title {
    font-weight: 300;
    font-size: 1em;
    padding: 0.2em
}

@media only screen and (max-width: 400px) {
    .info-wrapper {
        min-width: 2em;
        position: relative;
    }

    .info-icon {
      display: flex;
      justify-content: center;

    }

    .info-list-open {
        width: 200px;
        left: 50%;
        top: 50%;
        transform: translate(-60%, -50%);
    }
}