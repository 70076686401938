$font-family: 'Inter', sans-serif;

$primary-color: #019CFF;
$dark-accent: #464D64;
$secondary-accent: #EFF9FF;
$border-color: #D4D7E2;
$accent-color: #00A1F1;

$background-color: white;

$primary-tint: #00A1F1;

$light-grey: #EBEBEB;
$dark-grey: #484B4F;

$default-text: #181818;
$body-text: #4A4A4A;

$backgroundGray: rgba(120, 111, 163, 0.11); 

// $dark-background-color: #171717;
// $dark-default-text: #FFFFFF;
// $dark-body-text: #7E7E7E;
// $dark-backgroundGray: rgba(255, 255, 255, 0.07)

$dark-background-color: white;
$dark-default-text: #181818;
$dark-body-text: #4A4A4A;
$dark-backgroundGray: rgba(120, 111, 163, 0.11)