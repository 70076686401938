$green: #02b839;
$brown: #bb9011;
$orange: #f47906;
$red: #d4011a;

.scale-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.scale-dark-gray-number {
    color: #6b7280;
    margin-left: 2px
}

.scale-light-gray-number {
    color: #d1d5db;
    margin-left: 2px
}

.green-radio-button {
    width: 30px;
    height: 30px;
    border: 2px solid $green;
    border-radius: 50%;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.green-radio-button .green-checkmark {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 50%;
    background-color: $green;
    display: inline-block;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.green-radio-button input {
    display: none;
}

.green-radio-button input:checked + .green-checkmark {
    opacity: 1;
}

.brown-radio-button {
    width: 30px;
    height: 30px;
    border: 2px solid $brown;
    border-radius: 50%;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.brown-radio-button .brown-checkmark {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 50%;
    background-color: $brown;
    display: inline-block;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.brown-radio-button input {
    display: none;
}

.brown-radio-button input:checked + .brown-checkmark {
    opacity: 1;
}

.orange-radio-button {
    width: 30px;
    height: 30px;
    border: 2px solid $orange;
    border-radius: 50%;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.orange-radio-button .orange-checkmark {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 50%;
    background-color: $orange;
    display: inline-block;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.orange-radio-button input {
    display: none;
}

.orange-radio-button input:checked + .orange-checkmark {
    opacity: 1;
}

.red-radio-button {
    width: 30px;
    height: 30px;
    border: 2px solid $red;
    border-radius: 50%;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.red-radio-button .red-checkmark {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 50%;
    background-color: $red;
    display: inline-block;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.red-radio-button input {
    display: none;
}

.red-radio-button input:checked + .red-checkmark {
    opacity: 1;
}

@media only screen and (max-width: 400px) {
    .scale-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 1em;
    }

    .green-radio-button {
        width: 25px;
        height: 25px;
        border: 2px solid $green;
        border-radius: 50%;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .brown-radio-button {
        width: 25px;
        height: 25px;
        border: 2px solid $brown;
        border-radius: 50%;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .orange-radio-button {
        width: 25px;
        height: 25px;
        border: 2px solid $orange;
        border-radius: 50%;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .red-radio-button {
        width: 25px;
        height: 25px;
        border: 2px solid $red;
        border-radius: 50%;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}