  
#pagebreak {
    page-break-after: always;
}

body {
    text-align: left;
    font-family: sans-serif;
    font-size: 10pt;
}

h4 {
    width: 88%;
    background: #DDD;
    color: black;
    padding: 2pt 3%;
    margin: 0px 3% 0.5% 3%;
    font-size: 1.1em;
    font-weight: normal;
}

.avoidPageBreak {
    page-break-inside: avoid;
    break-inside: avoid;
}

th {
    color: #303030;
    font-weight: bold;
}

p {
    page-break-inside: avoid;
}

#heading {
    text-align: left;
    margin-bottom: 2em;
    margin-top: 2em;
}

#missing-detail {
    color: #FCA626;
}

.red {
    background-color: #FF2808;
}

.yellow {
    background-color: #F1D401;
}

.orange {
    background-color: #FFAB2A;
}

.green {
    background-color: #7ED321;
}

.blue {
    background-color: #5FB2E1;
}

.tag {
    color: white;
    display: inline-block;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 0.9em;
    min-width: 2em;
    text-align: center;
    margin-right: 0.5em;
    margin-left: 0.5em;
}

.minor-detail {
    color: #AAAAAA;
}

.detail-list {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    //border-color: black;
    border-radius: 15px;
    //border-width: 1px;
    //border-style: solid;
    background-color: #F1F5F9;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
    padding-bottom: 0.5em;
    margin-bottom: 1em;
    white-space: wrap;
}

.list-title{
    font-weight: 600;
    margin-bottom: 0.2em;
    margin-right: 0.5em;
}

.actions-list-title {
    font-weight: 600;
    margin-bottom: 0.2em;
    margin-right: 0.5em;
    font-size: 1.2em;
}

.list-detail{
    font-weight: 400;
    //margin-bottom: 1em;
}

.list-detail.absent {
    color: #FFAB2A
}

.list-detail.not-present {
    color: #AAAAAA
}

.detail-list-title {
    color: black;
    font-size: 1.2em;
    font-weight: bold;
    margin-right: 0.5em;
}

.detail-list-span-vertical{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
}

.detail-list-span-horizontal{
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: center;
    margin-top: 0.3em;
}

.detail-list-span-horizontal-center{
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: center;
}

.seperate {
    margin-top: -0.4em;
    margin-bottom: 0.5em; 
}

.detail-list-span-horizontal.with-spacing label {
    margin-left: 0.5em;
}

.form-summary-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding-left: 1em;
    padding-right: 1em;
}

.open-in-wayfind-button {
    background-color: rgba(76, 125, 175, 0.07);
    padding: 1em;
    color: #00A1F1;
    border-radius: 20px;
    margin-top: 1em;
    margin-bottom: 1em;
}

.plain-text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.plain-text-container .formButton {
    width: 98%;
}

.copy-success {
    position: absolute;
    font-size: 1em;
    font-weight: 400;
    opacity: 0;
    pointer-events: none;
    color: grey;
   
}

.show-success {
    animation: MoveUpDown 1.8s linear;
}

.print-head {
    display: none;
}

@keyframes MoveUpDown {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    10% {
        transform: scale(1);
        opacity: 1;
    }
    90%{
        transform: scale(1);
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale(0.8);
    }
  }

@media print {
    .form-summary {
        margin-top: 2em;
    }

    .print-head {
        position: relative;
        width: 100%;
        height: 100px;
        display: flex; 
        flex-direction: row;    
        flex-wrap: nowrap;              
        background: linear-gradient(90deg, rgba(43, 190, 98, 1) 0%, rgba(16, 185, 141, 1) 50%, rgba(50, 139, 183, 1) 100%);
        justify-content: space-between;
        align-items: center;
        color: white;
      }

      .print-image {
        margin-left: 1rem;
        width: 50%;
        height: 75px;
    }

      .print-head-text {
        width: 100%;
        margin-left: 10rem;
        font-size: larger;
      }

      .detail-list-title {
        background: linear-gradient(90deg, rgba(43, 190, 98, 0.4) 0%, rgba(16, 185, 141, 0.4) 50%, rgba(50, 139, 183, 0.4) 100%);
        color: black;
        font-size: 1.2em;
        font-weight: bold;
        margin-right: 0.5em;
        padding: 0.5em;
    }

    .tag {
        color: white;
        display: inline-block;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 8px;
        font-weight: 600;
        font-size: 0.9em;
        min-width: 2em;
        text-align: center;
        // margin: 1em 0.5em;
    }

    .print-header {
        display: none;
    }

    html, body {
        color: pink;
        width: 210mm;
        height: 297mm;
    }

    @page {
        size: 21cm 29.7cm;
        size: portrait;
        margin: 10mm 05mm 10mm 05mm;
    }
}



@media screen {
    .print-header {
        display: flex;
        flex-direction: column;
        align-content: stretch;
        padding-top: 2em;
        width: 100%;
    }

    .form-summary{
        max-width: 900px;
    }
}

.summary-section{
    margin-top: 2em;
}


.print-button-container{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-top: 1em;
    width: 100%;
}

.print-button-container .formButton{
    margin-right: 0.5em;
}

.summary-plaintext-input {
    opacity: 0;
    user-select: none;
    height: 0px;
    // width: 0px;
    border-style: none;
    outline: none;
    cursor: default;
    position: fixed;
}

.form-button-submit-pathway {
    border: 1px solid gray;
    font-size: large;
}

/* Mobile Styles */
@media only screen and (max-width: 850px) {
    .print-button-container{
        display: flex;
        flex-direction: column;
        align-content: stretch;
        align-items: stretch;
        margin-top: 1em;
        width: 100%;
    }

    .print-button-container .formButton {
        margin-top: 0.5em;
    }

    .copy-success { 
        margin-top: 0.2em;
    }

  }
  
  /* Tablet Styles */
@media only screen and (min-width: 851px) and (max-width: 1300px) {
    .print-button-container{
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        margin-top: 1em;
        width: 100%;
    }
}
  
  /* Desktop Styles */
@media only screen and (min-width: 1301px) {
    .print-button-container{
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        margin-top: 1em;
        width: 100%;
    }
}