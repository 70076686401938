
body {
    background-color: $background-color;
    font-family: $font-family;
    
    height: 100%;
    position: relative;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

}

body.disable-scroll {
    overflow: hidden;
}

.content-container {
    width: 1200px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.page-title {
    color: $default-text;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
    align-items: center;

    padding-left: 0.3em;
}


.form {
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 2em;
    min-height: 500px;
}

.form-page-badge-container {
    display: flex;
    margin-right: 0.3em;
}

.form-page-badge-container .form-badge {
    width: auto;
    height: 100%;
    font-size: 0.45em !important;
}

.page-description {
    opacity: 0.5;
    margin-left: 0.6em;
}

.page-description.no-validation {
    margin-bottom: 1em;
}

.form-header-validation {
    margin-bottom: 1em;
    padding-left: 0.6em;
}

.section-title {
    font-weight: 600;
    margin-bottom: 0.5em;
    color: $default-text;
    padding-left: 0.5em;
}

.section-description{
    font-weight: 400;
    margin-top: -0.5em;
    margin-bottom: 0.6em;
    color: rgb(176, 176, 176);
    padding-left: 0.5em;
}

.form-section {
    margin-bottom: 1.8em;
}


.navigation-buttons-container {
  display: flex;
  justify-content: space-between;
}


a:link {
    color: var(--primary-tint);
}

a:visited {
    color: var(--primary-tint);
}

a:hover {
    color: #36BCFF;
}

a:active {
    color: #0F7FB7;
}

/* Mobile Styles */
@media only screen and (max-width: 850px) {

    .page-title {
        margin-top: 0.3em;
        font-size: 1.5em;
    }


    .no-descrption {
        margin-bottom: 1em;
    }

    .section-title {
        font-size: 1em
    }

    .section-description{
        font-size: 0.9em;
        margin-left: 0.1em;
    }

    .right-panel {
        padding-left: 1em;
        padding-right: 1em;
    }

    .wrapper {
        padding-top: 4em;
    }

    .wrapper.hidden-nav {
        padding-top: 0em;
    }

    .content-container {
        max-width: 95%;
        min-height: 90vh;
    }

    .print-only {
        display: none;
    }

    .form {
        width: 100%;
        padding-left: 0em;
        padding-right: 0.2em;
        margin-bottom: 1em;
    }
}
  
/* Tablet Styles */
@media only screen and (min-width: 851px) and (max-width: 1300px) {
    .page-container {
        max-width: 1200;
        min-width: 90%;

        flex-direction: row;
    }

    .page-title {
        margin-top: 0.5em;
        
        font-size: 2em;
    }

    .no-descrption {
        margin-bottom: 0.8em;
    }

    .section-title {
        font-size: 1.2em;
    }

    .right-panel {
        padding-left: 2em;
        padding-right: 2em;
        width: 900px;
    }

    .wrapper {
        padding-top: 6em;
    }

    .wrapper.hidden-nav {
        padding-top: 0em;
    }

    .right-panel {
        height: 100%;
        flex: 2 2 2em;
    }

    .print-only {
        display: none;
    }

    .form {
        border-left-width: 2px;
        border-left-color: #EBEBEB;
        border-left-style: solid;
        margin-top:6em;
        margin-bottom:4em;
    }

    .sidebar {
        width: 35em;
        margin-bottom: 5em;
    }

    .content-container {
        margin-top: -6em;
        margin-bottom: -5em;
    }
}
  
/* Desktop Styles */
@media only screen and (min-width: 1301px) {

    .section-title {
        font-size: 1.2em;
    }

    .page-title {
        margin-top: 0.5em;
        font-size: 2em;
    }

    .no-descrption {
        margin-bottom: 0.8em;
    }

    .print-only {
        display: none;
    }

    .content-container {
        //margin-top: -6em;
        margin-bottom: -5em;
    }

    .form {
        border-left-width: 2px;
        border-left-color: #EBEBEB;
        border-left-style: solid;
        margin-top:6em;
        margin-bottom:4em;
    }
    
    .sidebar {
        width: 35em;
        margin-bottom: 6em;
    }

}

.insert-enter {
    opacity: 0.01;
    height: 0;
}

.insert-enter.insert-enter-active {
    opacity: 1;
    height: 1.5em;
    transition: 300ms;
}

.insert-leave {
    opacity: 1;
    height: 1.5em;
}

.insert-leave.insert-leave-active {
    opacity: 0.01;
    height: 0;
    transition: 300ms;
}

@media print {

    .right-panel {
        width: 100vw;
    }

    .page-container {
        max-width: 90%;

        flex-direction: row;
        padding-bottom: 0;
     }

    .section-title {
        font-size: 1.2em;
    }

    .page-title {
        margin-top: 0.5em;
        font-size: 2em;
    }

    .page-description {
        padding-bottom: 0.8em;
    }

    .no-descrption {
        padding-bottom: 0.8em;
    }

    .right-panel {
        padding-left: 2em;
        padding-right: 2em;
    }
    
    .right-panel {
        height: 100%;
        flex: 2 2 2em;
    }

    .next-page-button {
        display: none;
    }

    .previous-page-button {
        display: none;
    }

    .page-break	{ 
        display: block; 
        page-break-before: always; 
    }

    body, html {
        width: 100%;
    }

    .print-only {
        display: block;
    }
}

@media all {
	.page-break	{ display: none; }
}

@media (prefers-color-scheme: dark) {
    body {
        background-color: $dark-background-color;
    }

    .page-title {
        color: $dark-default-text;
    }

    .section-title{
        color: $dark-default-text;
    }

    .form {
        border-left-color: $dark-backgroundGray;
    }
    
}



  

