.form-date-picker {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

@media print {
    .form-date-picker {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media only screen and (max-width: 800px) {
  .form-date-picker {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}