.person-graph-cell-container {
    width: 2em;
    height: 2em;
    margin: 0.2em;
}

.person-graph-filled-cell{
    width: 2em;
}


/* Mobile Styles */
@media only screen and (max-width: 375px) {
    .person-graph-cell-container {
        width: 1em;
        height: 1em;
        margin: 0.2em;
    }
    
    .person-graph-filled-cell{
        width: 1em;
    }

}

/* Mobile Styles */
@media only screen  and (min-width: 375px) and (max-width: 850px) {
    .person-graph-cell-container {
        width: 1.5em;
        height: 1.5em;
        margin: 0.2em;
    }
    
    .person-graph-filled-cell{
        width: 1.5em;
    }

}