
.export-overlay-buttons-container .formButton {
    margin-left: 0.5em;
    width: 60%;
}

.export-overlay-buttons-container {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: center;
    margin-top: 0.8em;
}

.export-overlay-buttons-container div {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: center;
}

.card-overlay-exit-button-container {
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
    width: 100%;
    margin-top: 0.8em;
}

.card-overlay-exit-button-referral-container {
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
    margin-top: 0.8em;
}

.card-overlay-exit-button-container .formButton {
    width: 2em;
    height:2em;
}

.export-status-container {
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}
