
.active-indicator {
  background-color: transparent;
  border-radius: 16px;
  padding-left: 0.6em;
  padding-right: 0.6em;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

.active-indicator:hover {
  background-color: rgba(76, 125, 175, 0.04);
}

.form-input:focus {
  outline: none;
}

.form-radio {
    margin-right: 1em;
}

.example-enter {
    opacity: 0;
    max-height: 0;
  }
  
.example-enter.example-enter-active {
  opacity: 1;
  max-height: 3em;
  transition: 300ms;
}
  
.example-leave {
  opacity: 1;
  max-height: 3em;
}
  
.example-leave.example-leave-active {
  opacity: 0;
  max-height: 0;
  transition: 300ms;
}

.validation-enter {
  opacity: 0;
  max-height: 0;
}

.validation-enter.validation-enter-active {
  opacity: 1;
  max-height: 1.5em;
  transition: 300ms;
}

.validation-leave {
  opacity: 1;
  max-height: 1.5em;
}

.validation-leave.validation-leave-active {
  opacity: 0;
  max-height: 0;
  transition: 300ms;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    rgba(76, 125, 175, 0.32);
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:    rgba(76, 125, 175, 0.32);
 opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:    rgba(76, 125, 175, 0.32);
 opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:    rgba(76, 125, 175, 0.32);
}
::-ms-input-placeholder { /* Microsoft Edge */
 color:    rgba(76, 125, 175, 0.32);
}

::placeholder { /* Most modern browsers support this now. */
 color:    rgba(76, 125, 175, 0.32);
}

@media print { 
  .active-indicator:hover {
    background-color: transparent;
  }
}

.form-label.hidden {
  //max-height: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.form-label {
  //max-height: 1000px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}