.footer-wrapper {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    padding-bottom: 2em;
    background-color: white;
    z-index: 40;
}

.footer-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-links a {
    color: #4A4A4A;
    font-weight: 500;
    font-size: 0.9em;
    text-decoration:none;
    text-align: center;
    margin-right: 1.4em;
}

.footer-links button {
    color: #4A4A4A;
    background-color: transparent;
    font-weight: 500;
    font-size: 0.9em;
    text-decoration:none;
    text-align: center;
    border-style: none;
    cursor: pointer;
    padding: 0em;
    margin-right: 1.4em;
}

.footer-links button:focus {
    outline:0;
}

.footer-content .footer-copyright {
    color: #9B9B9B;
    font-weight: 400;
    font-size: 0.8em;
}

.footer-content{
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
    width: 1200px;
    border-top-color: #EBEBEB;
    border-top-style: solid;
    border-top-width: 2px;
    align-items: center;
    background-color: white;
    padding-top: 2em;
    height: 100%;
}

@media print {
    .footer-wrapper {
        display: none;
    }
}

/* Mobile Styles */
@media only screen and  (max-width: 850px) {

    .footer-content {
        flex-direction: column;
        align-content: space-around;
        justify-content: space-around;
        width: 90%;
        padding-top: 3em;
        padding-bottom: 3em;
    }

    .footer-wrapper {
        height: 10em;
    }

    .footer-copyright {
        margin-top: 2.8em;
    }
    
    .footer-links a {
        margin-top: 0.5em;
        font-size: 0.9em;
    }
 
    .footer-links button{
        margin-top: 0.5em;
        font-size: 0.9em;
    }
}

/* Tablet Styles */
@media only screen and (min-width: 851px) and (max-width: 1300px) {
    .footer-content {
        width: 90%;
    }

    .footer-wrapper{
        height: 5em;
    }
}

/* Desktop Styles */
@media only screen and (min-width: 1301px) {
    .footer-wrapper{
        height: 5em;
    }
}

@media (prefers-color-scheme: dark) {
    .footer-wrapper {
        background-color: $dark-background-color;
    }

    .footer-content {
        background-color: $dark-background-color;
    }

    .footer-content{
        border-top-color: $dark-backgroundGray;
    }
    
}