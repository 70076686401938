.card-overlay-buttons-container {
    top: 0.5em;
    right: -0.2em;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
}

.session-card-exit {
    height: 20px;
    width: 20px;
}