.login-card.open {
    background-color:   white;
    border-radius:        15px;
    padding-top:          1em;
    padding-left:         1em;
    padding-right:        1em;
    padding-bottom:       1.5em;
    overflow:             scroll;
    z-index: 105;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.login-manager-aligner {
    max-width: 90%;
    width: 30em;
    height: 100%;
}

.login-title {
    font-size: 2em;
    color: $default-text;
    font-weight: 700;
    text-align: center;
}

.login-detail {
    color: #909294;
}

.login-form-section {
    text-align: center;
}

.login-form-group {
    display: inline-block;
    width: 80%;
}

.login-text-input-group {
    margin-top: 1em;
    margin-bottom: 1em;
}

.login-text-input-group .form-text-field {
    margin-bottom: 0.5em;
}

.skip-authentication {
    padding-top: 0.5em;
}