$green: #02b839;
$brown: #bb9011;
$orange: #f47906;
$red: #d4011a;

.scale-key-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 16px;
}

.smiley-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
}

.smiley {
    margin: auto;
}

.smiley-text-green {
    color: $green;
    font-size: 14px;
}

.smiley-line-green {
    background-color: $green;
    height: 10px;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.smiley-text-brown {
    color: $brown;
    font-size: 14px;
}

.smiley-line-brown {
    background-color: $brown;
    height: 10px;
}

.smiley-text-orange {
    color: $orange;
    font-size: 14px;
}

.smiley-line-orange {
    background-color: $orange;
    height: 10px;
}

.smiley-text-red {
    color: $red;
    font-size: 14px;
}

.smiley-line-red {
    background-color: $red;
    height: 10px;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}