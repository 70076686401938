.environment-manager-container {
    position: fixed;
    z-index: 102;
    overflow-y: scroll;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.environment-card.open {
    background-color:   white;
    border-radius:        15px;
    padding-left:         1em;
    padding-right:        1em;
    padding-bottom:       0.5em;
    overflow:             scroll;
    margin-bottom:        1em;
    max-width: 90%;
    width: 40em;
    z-index: 105;
    max-height: 80vh;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.environment-card {
    z-index: 105;
}