.blood-pressure-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: stretch;
}

.blood-pressure-slash {
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-top: 0.5em;
    color: #8C8C8C;
}

@media print {
    .blood-pressure-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: stretch;
    }

    .blood-pressure-slash.no-pressure{
        display: none;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
    }
}