.multi-grid-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.grid-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    margin-top: 1em;
}

.picture-card-wrapper {
    flex: 0 0 32%;
    height: 12.5rem;
    margin-bottom: 3rem;
}

.multi-picture-card {
    width: 12.5em;
    height: 12.5em;
    border-radius: 1em;
    margin: 0.2em;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.multi-picture-card:hover {
    transform: scale(1.02);
}

.picture-card {
    width: 12.5em;
    height: 12.5em;
    border-radius: 1em;
    margin: 0.2em;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.picture-card:hover {
    transform: scale(1.02);
}

.picture-card-check-one {
    width: 12.5em;
    height: 12.5em;
    border-radius: 1em;
    margin: 0.2em;
}

.card-button-wrapper {
    display: flex;
    justify-content: end;
    margin-top: 2em;
    margin-right: 2.5em;
}

.card-button {
	border-radius: 9999px;
    margin-right: 5px;
    text-align: center;
    background-color:#e6eef3;
    color: rgba(1, 156, 255, 1);
    padding: 0.3em 2em;
    display: flex;
    flex-direction: row;
    place-content: center;
    align-items: center;
    font-size: 1em;
    border: none;
    outline: none;
}

.card-button:hover {
    background-color: #d3dbdf;
}

.card-button:focus {
    outline: none;
    border: none;
}

.card-button-active {
    border-radius: 9999px;
    margin-right: 5px;
    text-align: center;
    color:#fff;
    background-color: rgba(1, 156, 255, 1);
    padding: 0.3em 2em;
    display: flex;
    flex-direction: row;
    place-content: center;
    align-items: center;
    font-size: 1em;
    outline: none;
    border: none;
}

.multi-card-selected {
    width: 12.5em;
    height: 12.5em;
    border-radius: 1em;
    margin: 0.2em;
    border: 0.2em rgba(1, 156, 255, 1) solid;
    padding: 0.1em;
    cursor: pointer;
}

.picture-card-selected {
    width: 12.5em;
    height: 12.5em;
    border-radius: 1em;
    margin: 0.2em;
    border: 0.2em rgba(1, 156, 255, 1) solid;
    padding: 0.1em;
    cursor: pointer;
}

.card-label {
    display: flex;
    justify-content: center;
    font-size: 16px;
    color:#000;
    font-weight: 500;
}

//Mobile Screens
@media only screen and (max-width: 540px) {
    .multi-grid-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 1em;
        grid-row-gap: 1em;
        margin-top: 1em;
        margin-left: 0.4em;
        margin-right: 1em;
    }

    .grid-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 1em;
        grid-row-gap: 1em;
        margin-top: 1em;
        margin-left: 1em;
        margin-right: 1em;
    }

    .multi-picture-card {
        width: 12em;
        height: 12em;
        border-radius: 1em;
        margin: -1em;
    }

    .picture-card {
        width: 12em;
        height: 12em;
        border-radius: 1em;
        margin-left: -1em;
        margin-right: -1em;
        margin-top: -1.5em;
    }

    .picture-card-check-one {
        width: 12em;
        height: 12em;
        border-radius: 1em;
        margin-left: -1em;
        margin-right: -1em;
        margin-top: -1.5em;
    }

    .card-button-wrapper {
        display: flex;
        justify-content: end;
        margin-top: 2em;
        margin-left: 20em;
    }
}

@media only screen and (max-width: 415px) {
    .multi-grid-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 1em;
        grid-row-gap: 1em;
        margin-top: 1em;
        margin-left: 0.4em;
        margin-right: 1em;
    }

    .grid-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 1em;
        grid-row-gap: 1em;
        margin-top: 1em;
        margin-left: 1em;
        margin-right: 1em;
    }

    .multi-picture-card {
        width: 10em;
        height: 10em;
        border-radius: 1em;
        margin: -1em;
    }

    .picture-card {
        width: 10em;
        height: 10em;
        border-radius: 1em;
        margin-left: -1em;
        margin-right: -1em;
        margin-top: -1.5em;
    }

    .picture-card-check-one {
        width: 10em;
        height: 10em;
        border-radius: 1em;
        margin-left: -1em;
        margin-right: -1em;
        margin-top: -1.5em;
    }

    .card-button-wrapper {
        display: flex;
        justify-content: end;
        margin-top: 2em;
        margin-left: 20em;
    }

    .card-label {
        display: flex;
        justify-content: center;
        margin-top: 1em;
        font-size: 16px;
        color:#000;
        font-weight: 500;
    }
}

@media only screen and (max-width: 400px) {
    .multi-grid-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 1em;
        grid-row-gap: 1em;
        margin-top: 1em;
        margin-left: -1em;
        margin-right: 1em;
    }

    .grid-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 1em;
        grid-row-gap: 1em;
        margin-top: 20px;
        margin-left: 1em;
        margin-right: 1em;
    }

    .multi-picture-card {
        width: 10em;
        height: 10em;
        border-radius: 1em;
        margin: -1em;
    }

    .picture-card {
        width: 10em;
        height: 10em;
        border-radius: 1em;
        margin-left: -1em;
        margin-right: -1em;
        margin-top: -1.5em;
    }

    .picture-card-check-one {
        width: 10em;
        height: 10em;
        border-radius: 1em;
        margin-left: -1em;
        margin-right: -1em;
        margin-top: -1.5em;
    }

    .picture-card-selected {
        width: 10em;
        height: 10em;
        border-radius: 1em;
        border: 0.2em rgba(1, 156, 255, 1) solid;
        padding: 0.1em;
        margin-left: -1em;
        margin-right: -1em;
        margin-top: -1.5em;
        cursor: pointer;
    }

    .multi-card-selected {
        width: 10em;
        height: 10em;
        border-radius: 1em;
        margin: -1em;
        border: 0.2em rgba(1, 156, 255, 1) solid;
        padding: 0.1em;
        cursor: pointer;
    }

    .card-button-wrapper {
        display: flex;
        justify-content: end;
        margin-top: 2em;
        margin-left: 20em;
    }
}

@media only screen and (max-width: 375px) {
    .multi-picture-card {
        width: 10em;
        height: 10em;
        border-radius: 1em;
        margin: -1em;
    }

    .picture-card {
        width: 10em;
        height: 10em;
        border-radius: 1em;
        margin: -1em;
    }

    .picture-card-selected {
        width: 10em;
        height: 10em;
        border-radius: 1em;
        border: 0.2em rgba(1, 156, 255, 1) solid;
        padding: 0.1em;
        margin: -1em;
        cursor: pointer;
    }

    .multi-card-selected {
        width: 10em;
        height: 10em;
        border-radius: 1em;
        margin: -1em;
        border: 0.2em rgba(1, 156, 255, 1) solid;
        padding: 0.1em;
        cursor: pointer;
    }

    .card-label {
        display: flex;
        justify-content: center;
        margin-top: 1em;
        font-size: 16px;
        color:#000;
        font-weight: 500;
    }
}
