.form-section-inline {
    margin-bottom: 1.8em;
    margin-left: 0.62em;
    padding-left: 0.75em;
    padding-top: 0.5em;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
    background: #F9F9F9;
    border-left-width: 5px;
    border-left-color: #DD95B1;
    border-left-style: solid;
    border-radius: 3px;
}

.form-section-inline .section-title {
    padding-top: 0.5em;
    color: #DD95B1;
}

.form-section-inline .form-detail {
    padding-bottom: 0.5em;
}

.form-section-inline .content-view-subcell.subcell-padding {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}