.sidebar {
    height: 100vh;

    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    overflow-y: scroll;

    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.sidebar-content {
    //height: 92%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar-links {
    padding-top: 0.5em;
}

.export-summary-container {
    background-color: rgba(76, 125, 175, 0.07);
    border-radius: 15px;
    margin-left: 2em;
    margin-right: 1em;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0.8em;
    cursor: pointer;
}

.sidebar-item {
    padding-left: 1em;
    margin-left: 0.5em;
    user-select: none;

    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

.sidebar-item.selectable {
    cursor: pointer;
}

.sidebar-item.large-title:hover {
    color: #FFF;
}

.sidebar-item-header {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 0.5em;
    padding-right: 1em;
    cursor: pointer;
    overflow: hidden;
}

.form-pie-container {
    margin-right: 0.2em;
    margin-left: 0.1em; 
}

.sidebar-item-header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.sidebar-item-content-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sidebar-item-header:hover {
    background-color: rgba(76, 125, 175, 0.05);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

.sidebar-item-name {
    font-family: $font-family;
    font-weight: 500;
    font-size: 0.9em;
    color: $body-text;
}

.sidebar-item-name.active {
    color: var(--accent-color);
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

.sidebar-item-icon-container {
    width: 40px;
}

.sidebar-item-icon {
    width: 100%;
    position: relative;
}

.sidebar-item:hover {
    color: var(--accent-color);
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

.sidebar-item-children {
    margin-left: 1em;
}

.notification-badge {
    background-color: #FF2808;
    opacity: 1;
    border-radius: 100px;
    width: 0.8em;
    height: 0.8em;
    position: absolute;
    right: 0em;
    top: 0em;
}

.sidebar-image-container {
    position:relative;
    display:inline-block;
}

.side-bar-form-badge {
    border-radius: 5px;
    width: 1em;
    height: 1em;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.side-bar-form-badge-inner {
    width: 0.5em;
    height: 0.5em;
    border-radius: 2.5px;
    opacity: 0.9;
}

/* Mobile Styles */
@media only screen and (max-width: 850px) {
    .sidebar {
        border: none;
        position: fixed;
        background-color: rgba(255,255,255,0.98);
        z-index: 105;
        width: auto;
        margin-top: 3.2em;
        margin-right: 0.5em;
        margin-bottom: 0.5em;
        overflow: hidden;
        max-height: 80vh;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        overflow-y: scroll;

        padding-top: 0.4em;
        padding-bottom: 0.4em;

        -webkit-box-shadow: -1px 6px 45px -7px rgba(0,0,0,0.24);
        -moz-box-shadow: -1px 6px 45px -7px rgba(0,0,0,0.24);
        box-shadow: -1px 6px 45px -7px rgba(0,0,0,0.24);
        
    }

    .sidebar-content {
        height: 100%;
    }

    .export-summary-container {
        margin-top: auto;
        margin-bottom: 1em;
    }

    .sidebar-item {
        padding-left: 0.2em;
        margin-left: 0.1em;
    }

    .sidebar.collapsed {
        padding: 0em;
        border: none;
        padding-bottom: 0em;
        max-height: 0px;
        pointer-events: none;
        
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }

    .sidebar-item-header {
        padding-right: 2em;
    }

    .sidebar-item-header:hover {
        background-color: rgba(76, 125, 175, 0.05);
        margin-right: 0.4em;
        border-radius: 8px;
        -webkit-transition: all 0.1s ease-in-out;
        -moz-transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
    }

    .sidebar-item-header.active {
        border-right-color: transparent;
    }
}

 /* Tablet Styles */
 @media only screen and (min-width: 851px) and (max-width: 1300px) {

    .sidebar {
        height: 100vh;

        display: flex;
        /* flex-direction: column; */
        justify-content: stretch;
        align-items: stretch;
        align-content: space-around;
    }

    .sidebar-links {
        position: relative;
        padding-top: 1em;
    }

    .acive-indicator {
        background-color: var(--accent-color);
        width: 3px;
        height: 2.5em;
        position: absolute;
        right: 0;
        border-radius: 100px;
    }

    .sidebar.offset{
        padding-top: 6em;
    }

    .sidebar-content {
        margin-top: 6em;
        width: 100%;
    }

    .export-summary-container {
        margin-bottom: 1em;
        padding-top: auto;
        padding-bottom: 1em;
        
    }
    
  }
  
  /* Desktop Styles */
  @media only screen and (min-width: 1301px) {
    
    .sidebar {
        height: 100vh;
        display: flex;
        /* flex-direction: column; */
        justify-content: stretch;
        align-items: stretch;
        align-content: space-around;
    }

    .sidebar-links {
        position: relative;
        padding-top: 1em;
    }

    .acive-indicator {
        background-color: var(--accent-color);
        width: 3px;
        height: 2.7em;
        position: absolute;
        right: 0;
        border-radius: 100px;
    }

    .sidebar-content {
        margin-top: 6em;
        margin-bottom: 5em;
        width: 100%;
    }

    .export-summary-container {
        margin-top: auto;
        margin-bottom: 1em;
    }

}

@media print {
    .sidebar {
        display: none;
    }
}






@media (prefers-color-scheme: dark) {
    .sidebar-item-name {
        color: $dark-default-text;
    }
}