.select-menu {
    min-width: 15em;
    position: relative;
}

.select-menu * {
    box-sizing: border-box;
}

.select {
    border: 2px solid #f2f6f9;
    background: #fff;
    color: #cad8e7;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 0.6em;
    padding: 0.6em;
    cursor: pointer;
    transition: background 0.3s;
}

.select:hover {
    color: #009cff;
}

.selected {
    margin-right: 1em;
    color: #009cff;
}

.placeholder {
    margin-right: 0.3em;
}

.menu {
    opacity: 0;
    display: none;
    transition: 0.2s;
    z-index: 99;
}

.menu-open {
    width: 120%;
    list-style: none;
    padding: 0.2em 0.5em;
    background: #fff;
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
    border-radius: 0.5em;
    color: #000;
    position: absolute;
    top: 3em;
    left: 40%;
    transform: translateX(-50%);
    opacity: 1;
    display: block;
    transition: 0.2s;
    z-index: 99;
}

.option {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    padding: 0.6em 0.6em;
    cursor: pointer;
}

.option:hover {
    background: #009cff;
    color: #fff;
    border-radius: 0.2em; 
}

@media only screen and (max-width: 400px) {
    .select-menu {
        min-width: 12em;
        position: relative;
    }


    .menu-open {
        width: 90%;
        list-style: none;
        padding: 0.2em 0.5em;
        background: #fff;
        box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
        border-radius: 0.5em;
        color: #000;
        position: absolute;
        top: 3em;
        left: 40%;
        transform: translateX(-45%);
        opacity: 1;
        display: block;
        transition: 0.2s;
        z-index: 99;
    }
}
