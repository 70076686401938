
.header-left-container {
    display: flex;
    flex-direction: row;
    width: 20%;
    align-items: center;
}

.header-logo {
    width: auto;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
} 

.quidel-header-logo {
    width: 240px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; 
}

.header-logo.docked {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.quidel-header-logo.docked {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.more-dots{
    width: auto;
}

.header-container {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    -webkit-transform: translate3d(0, 0, 0);
    width: 100%;
    background-color: #004A79;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.header-title {
    display: flex;
    flex-direction: row;
    color: white;
    font-weight: 500;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.header-container.docked {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.header-menu-container {
    margin-top: 30px;
    position: absolute;
    right: 7em;
}

.header-center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
}

.environment-tag-container-mobile{
    display: flex;
    flex-direction: row;
}

.header-main-title {
    color: white;
    font-size: 3em;
    font-weight: 600;

    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.header-main-title.docked {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.header-background-container {
    position: absolute;
    width: 100%;
    top: 0;
    
    overflow: hidden;
    z-index: -1;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.header-background-container.docked {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.header-background-container img {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right,#004A79 0%,#3A8DC1 100%);
}

.header-content-wrapper {
    width: 100%;
    height: 100%;
    display: flex; 
    flex-direction: row;    
    flex-wrap: nowrap;              
    background: linear-gradient(90deg, rgba(43, 190, 98, 1) 0%, rgba(16, 185, 141, 1) 50%, rgba(50, 139, 183, 1) 100%);
    justify-content: center;
    align-items: center;
}

.header-content-wrapper-quidel-ortho {
    width: 100%;
    height: 100%;
    display: flex; 
    flex-direction: row;    
    flex-wrap: nowrap;              
    background: url( '../../images/Quidel-BG.svg');
    justify-content: center;
    align-items: center;
}

.header-content-container {
    display: flex; 
    flex-direction: row;    
    flex-wrap: nowrap;              

    justify-content: center;
    align-items: center;

    height: 100%;
}

.header-right-container {
    width: 20%;
    text-align: -webkit-right;
}

.home-button {
    display: flex; 
    flex-direction: row;    
    flex-wrap: nowrap;              
    cursor: pointer;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.header-contianer.docked {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.pathway-button {
    height: 100%;
    border-style: none;
    background-color: transparent;
    user-select: none;
    color: white;
    cursor: pointer;
    text-align: right;
}

.pathway-button:hover {
    text-decoration: underline;
}

.pathway-button:focus {
    outline: none;
}

.header-menu-button-container {
    flex-direction: row;
    justify-content: flex-end;
    width: fit-content;
}

.header-menu-button {
    height: 2.5em;
    border-style: none;
    border-radius: 10px;
    background-color: #FFFFFF33;
    color: #FFFFFF;
    display: flex;
    text-decoration: none;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    padding-left: 1em;
    padding-right: 1em;
    align-items: center;
}

.header-menu-button-username-container {
    display: flex;
    align-items: center;
    padding-right: 0.5em;

    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.header-menu-button-image-container {
    display: flex;
    align-items: center;
    margin-left: 4px;
}

.header-menu-container {
    position: absolute;
}

.header-menu {
    background-color:  white;
    border-radius: 15px;
    min-height: 3em;
    min-width: 20em;
    opacity: 0.98;
    user-select: none;

    -webkit-box-shadow: -1px 6px 45px -7px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: -1px 6px 45px -7px rgba(0, 0, 0, 0.15);
    box-shadow: -1px 6px 45px -7px rgba(0, 0, 0, 0.15);
    
    .profile-details-container{
      display: flex;
      height: 10em;
      border-radius: 15px 15px 0 0;
      background-image: linear-gradient(90deg, rgba(43, 190, 98, 1) 0%, rgba(31,163,161,255) 47%,   rgba(50, 139, 183, 1)100%);
      justify-content: center;
      padding: 0.5em;

      .image-container{
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0.5em;
      }

      .details-container{
        display: flex;
        flex: 2;
        flex-direction: column;
        justify-content: center;
        align-items: first baseline;
        width: 100%;
        height: 100%;
        padding: 0.5em;
      }
    }

    .hpi-identification-container {

      .hpi-label, .level-identification-label{ 
        display: flex;
        padding: 0.5em;
        padding-bottom: 0;
        font-size: 1.5em;
        font-weight: 600;
      }

      .hpi-number, .level-identification {
        display: flex;
        padding: 0.5em;
        font-size: 1.5em;
        padding-top: 0;
      }
    }
}

// .header-menu-label-container {
//     display: flex;
//     width: 100%;
//     margin-bottom: 0.5rem;
//     margin-bottom: 0.5rem;
//     justify-content: center;
//     align-items: center;
// }

.header-menu button {
    border-style: none;
    background-color: transparent;
    user-select: none;
    cursor: pointer;
    text-align: right;
    font-size: 0.9em;
    font-weight: 500;
    z-index: 5;
    width: 100%;
    text-align: center;
    height: 3em;
}

.pdf-button {
    color: var(--primary-tint);
}

.open-wayfind-button{
    color: var(--primary-tint);
}

.view-results-button{
    color: var(--primary-tint);
}

.end-session-button {
    color: #FF2808;
}

.open-wayfind-button {
    color: #4A90E2;
}

.logout-button {
    color: #FF2808;
}

.header-menu button:hover {
    border-radius: 12px;
    background-color: rgba(76, 125, 175, 0.04);
}

.header-notification-badge {
    background-color: #FF2808;
    border-radius: 100px;
    width: 0.8em;
    height: 0.8em;
    position: absolute;
    right: -0.1em;
    top: 0.3em;
}

.left-hamburger-container {
    padding-top: 0.4em;
    position:relative;
    display:inline-block;
}

.version-label {
    font-weight: 400;
    width: 100%;
    color: gray;
    font-size: 0.7em;
    text-align: center;
}

.username-label {
    font-weight: 400;
    width: 100%;
    color: #181818;
    font-size: 0.8em;
    text-align: left;
    padding-top: 0.5em;
    margin-bottom: 0;
}

.environemnt-button {
    border-style: none;
    color: white;
    font-size: 0.7em;
    font-weight: 600;
    width: 100%;
    height: auto;
    cursor: pointer;
    border-style: none;
    background-color: transparent;
    padding-left:0;
    padding-right:0;
    padding-bottom:0;
    padding-top: 0;
}

.environemnt-button-container {
    display: flex;
    justify-content: stretch;
    align-content: stretch;
    align-items: stretch;

    background-color: rgba(250, 250, 250, 0.5);
    width: 15px;
    height:  15px;
    border-radius: 10em;
    margin-bottom: 0.6em;
    margin-left: 0.2em;
    z-index: 12;
    
}

.environment-tag-mobile {
    font-weight: 600;
    margin-left: 0.2em;
    font-size: 0.7em;
    color: white;
}

.environment-tag {
    font-weight: 600;
    margin-left: 0.2em;
    color: white;
}

.environment-button-container-mobile {
    display: flex;
    justify-content: stretch;
    align-content: stretch;
    align-items: stretch;

    background-color: rgba(250, 250, 250, 0.5);
    width: 15px;
    height:  15px;
    border-radius: 10em;
    margin-bottom: 0.6em;
    margin-left: 0.2em;
    z-index: 12;
}

.environemnt-button-mobile {
    border-style: none;
    color: white;
    font-size: 0.7em;
    font-weight: 600;
    width: 100%;
    height: auto;
    cursor: pointer;
    border-style: none;
    background-color: transparent;
    padding-left:0;
    padding-right:0;
    padding-bottom:0;
    padding-top: 0;
}

.info-button-graphic{
    width: 90%;
    height: 90%;
    padding-bottom: 0.2em;
}

/* Mobile Styles */
@media only screen and (max-width: 850px) { 

    .header-container {
        height: 4em;
    }

    .header-background-container {
        height: 4em;
    }

    .header-content-container {
        margin-top: 0em;
        min-width: 95%;
    }

    .header-main-title {
        font-size: 1em;
        font-weight: 600;
    }

    .header-title {
        display: none;
    }

    .header-menu-button-username {
        display: none;
    }

    .header-logo {
        display: none;
        height: 2em;
        margin-right: 0.5em;
        margin-left: 0.5em;
    }

    .quidel-header-logo {
        display: none;
        height: 2em;
        margin-right: 0.5em;
        margin-left: 0.5em;
    }

    .hamburger-container {
        display: inline-block;
    }

    .more-dots{
        height: 40px;
    }

    .header-menu {
        top: 3em;
        right: 1.1em;
        margin-top: 0.4em;
    }

    .environment-tag {
        display: none;
    }

    .environemnt-button-container{
        display: none;
    }
  }
  
  /* Tablet Styles */
  @media only screen and (min-width: 851px) and (max-width: 1300px) {

    .header-container.docked {
        height: 6em;
        
    }

    .header-background-container.docked {
        height: 6em;
    }

    .header-container {
        height: 10em;
    }

    .header-background-container {
        height: 10em;
    }

    .header-content-container {
        min-width: 94%;
        max-width: 1130px;
    }

    .header-main-title {
        font-size: 2em;
        font-weight: 600;
    }

    .header-main-title.docked {
        font-size: 2em;
    }

    .header-title {
        font-size: 1.6em;
    }

    .header-title.docked {
        font-size: 1.5em;
    }

    .header-logo {
        height: 3.5em;
        margin-right: 1em;
    }

    .quidel-header-logo {
        height: 3.5em;
        margin-right: 1em;
    }

    .header-logo.docked {
        height: 2.5em;
    }

    .quidel-header-logo.docked {
        height: 2.5em;
    }

    .hamburger-container {
        display: none;
    }

    .more-dots{
        height: 2.5em;
    }

    .header-menu {
        top: 6em;
        right: 2em;
    }

    .header-menu.docked {
        top: 4.7em;
    }

    .triangle.left {
        display: none;
    }

    .header-notification-badge{
        display: none;
    }

    .environment-tag {
        font-size: 1em;
    }

    .environment-tag-container-mobile {
        display: none;
    }

  }
  
  /* Desktop Styles */
  @media only screen and (min-width: 1301px) {

    .header-background-container.docked {
        height: 6em;
    }

    .header-container.docked {
        height: 6em;
        -webkit-transform: translate3d(0,0,0);

        -webkit-box-shadow: 0px 5px 10px -5px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 5px 10px -5px rgba(0,0,0,0.2);
        box-shadow: 0px 5px 10px -5px rgba(0,0,0,0.2);
    }

    .header-container {
        height: 10em;
    }

    .header-background-container {
        height: 10em;
    }

    .header-content-container {
        max-width: 90%;
        min-width: 1140px;
    }

    .header-main-title {
        font-size: 2.6em;
        font-weight: 600;
    }

    .header-main-title.docked {
        font-size: 1.6em;
    }

    .header-title {
        font-size: 2em;
    }

    .header-title.docked {
        font-size: 1.5em;
    }

    .header-logo {
        height: 3.5em;
        margin-right: 1em;
    }

    .quidel-header-logo {
        height: 3.5em;
        margin-right: 1em;
    }

    .header-logo.docked {
        height: 3.5em;
    }

    .quidel-header-logo.docked {
        height: 3.5em;
    }

    .hamburger-container  {
        display: none;
    }

    .more-dots{
        height: 2.5em;
    }

    .header-menu.docked {
        top: 4.7em;
    }

    .triangle.left {
        display: none;
    }

    .header-notification-badge{
        display: none;
    }

    .environment-tag {
        font-size: 1em;
    }

    .environment-tag-container-mobile {
        display: none;
    }

  }

  .hamburger-container {
    
    cursor: pointer;

  }

  .thing {
    background-color: red;
  }
  
  .bar1, .bar2, .bar3 {
    width: 28px;
    height: 3px;
    border-radius: 1.5px;
    background-color: white;
    margin: 6px 0;
    transition: 0.3s;
  }
  
  /* Rotate first bar */
 .bar1.change {
    -webkit-transform: rotate(-45deg) translate(-5px, 5px) ;
    transform: rotate(-45deg) translate(-5px, 5px) ;
  }
  
  /* Fade out the second bar */
  .bar2.change  {
    opacity: 0;
  }
  
  /* Rotate last bar */
   .bar3.change {
    -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
    transform: rotate(45deg) translate(-8px, -8px) ;
  }

  .triangle{
    position: absolute;
	background-color: white;
    text-align: left;
  }

.triangle.left {
    left: 1.5em;
    top: -1em;
    z-index: 10;
}

.triangle:before,
.triangle:after {
	content: '';
	position: absolute;
	background-color: inherit;
}
.triangle,
.triangle:before,
.triangle:after {
	width:  0.5em;
	height: 0.5em;
	border-top-right-radius: 50%;
}

.triangle {
	transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
}
.triangle:before {
	transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
}
.triangle:after {
	transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
}

@media print {
    .header-container {
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .header-menu-container {
        right: 1em;
        left: 1em;
    }
}

@media screen and (max-width: 1400px) {
    .header-menu-container {
        // right: 2em;
    }
}