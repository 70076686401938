
.session-overlay {
    opacity:              0.3;
    background-color:   black;
    position:             fixed;
    width:                100%;
    height:               100%;
    opacity:              0.5; 
    background:         #000; 
    width:                100%;
    height:               100%; 
    z-index:              102;
    top:                  0; 
    left:                 0; 
    position:             fixed; 
}


.session-manager-container {
    position: fixed;
    z-index: 105;
    overflow-y: scroll;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    
}

.session-manager-aligner {
    max-width: 90%;
    width: 40em;
    height: 100%;
}

.faux-card {
    opacity: 0;
	height: 15%;
}

.session-card {
    background-color:   #F3F6F9;
    border-radius:        15px;
    padding-left:         1em;
    padding-right:        1em;
    padding-bottom:       0.5em;
    overflow:             hidden;
    margin-bottom:        1em;
    z-index: 105;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.session-card.open{
    background-color:   white;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.session-body {
    margin-top:           1em;
    margin-left:          0.8em;
    margin-right:         0.8em;
    margin-bottom:        1em;
}

.existing-session-cell {
    background-color:   rgba(76, 125, 175, 0.07);
    border-radius:        15px;
    padding:              0.8em;
    margin-top:           0.5em;
    cursor:               pointer;
}

.existing-session-button {
    display: flex;
    flex-direction: row;
}

.session-title {
    margin-left: 0.4em;
    margin-top: 0.5em;
    font-size: 2em;
    color: $default-text;
    font-weight: 700;
}

@media (prefers-color-scheme: dark) {
    .session-title {
        color: $dark-default-text;
    }


    .environment-title {
        color: $dark-default-text;
    }
}

.environment-title {
    margin-left: 0.4em;
    font-size: 2em;
    color: $default-text;
    font-weight: 700;
}

.environment-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    align-content: stretch;
    cursor: pointer;
    margin-left: 0.4em;
    margin-right: 0.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.session-environment-title {
    margin-left: 0.4em;
    margin-top: 0.5em;
    font-size: 1em;
    color: var(--primary-tint);
    font-weight: 400;
}

.session-detail {
    margin-left: 1em;
    font-size: 0.9em;
}

.environemnt-session-detail {
    margin-left: 0.8em;
}

.pilot-ended-label {
    padding-top: 0.5em;
    padding-bottom: 1em;
}

.new-pathway-button {
    //padding: 0.6em;
    margin-top: 1em;
    margin-bottom: 0.2em;
}

.new-session-container {
    margin-top: 2em;
}

.environemt-session-container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.environemt-session-container .session-card {
    max-width:            100%;
    
}

/* Mobile Styles */
@media only screen and (max-width: 850px) {

    .session-title {
        font-size: 1.8em;
    }

    .session-card {
        padding-left: 0.8em;
        padding-right: 0.8em;
        padding-bottom: 0.6em;
    }

    .new-pathway-button {
        margin-bottom: 0.5em;
    }

    .faux-card {
        opacity: 0;
        height: 5em;
    }

  }
  
  /* Tablet Styles */
  @media only screen and (min-width: 851px) and (max-width: 1300px) {
  
  }
  
  /* Desktop Styles */
  @media only screen and (min-width: 1301px) {
  
  }

  .referral-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .referral-detail {
    font-size: 0.9em;
    margin-left: 1em;
    margin-right: 1em;
    color: rgb(176, 176, 176);
  }

.referral-select {
    height: 40px;
    margin: 0.4em;
    border-radius: 12px;
    border-color: rgba(76, 125, 175, 0.07);;
    border-width: 2px;
    color: #019CFF;
    padding-left: 0.8em;
    padding-right: 0.8em;
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
}

.referral-select:after {
    content: '\2304';
    font-size: 30px;
    line-height: 23px;
    padding-right: 2px;
}

.referral-provider-selection {
    margin-bottom: 1em;
}

.referral-section-title {
    font-weight: 600;
    font-size: 1.4em;
    margin-bottom: 0.2em;
}

.referral-section-subtitle {
    font-weight: 500;
    font-size: 1em;
    margin-top: 1em;
}

.referral-section-subtitle .form-text-field {
    padding-left: 0.6em;
}

.referral-details-section {
    margin-top: 1em;
}

.referral-detail.missing {
    color: red !important; 
}