.form-text-field {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    background-color: white;
    padding-right: 0.5em;
    overflow: hidden;
}

.form-long-text-field {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    background-color: white;
    padding-right: 0.5em;
    overflow: hidden;
}

@media print {
    .form-text-field {
        border-style: none;
    }

    .form-long-text-field {
        border-style: none;
    }

    .form-text-field.is-empty {
        display: none;
    }

    .form-long-text-field.is-empty {
        display: none;
    }
}