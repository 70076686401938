body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "FiraSansRegular";
  src: local("FiraSansRegular"),
   url("./styles/fonts/FiraSans-Regular.ttf") format("truetype");
  font-weight: 400;
 }

 @font-face {
  font-family: 'FiraSansBold';
  src: local("FiraSansBold"),
    url('./styles/fonts/FiraSans-Bold.ttf') format('truetype');
  font-weight: 700;
}
