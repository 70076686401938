.formButton button{
    width: 100%;
    height: 100%;
    border-style: none;
    background-color: transparent;
    user-select: none;
    
    cursor: pointer;
}

.formButton {
    border-radius: 500px;
    
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-Content: stretch;
    align-Items: center;
    align-content: center;
    width: auto;
    cursor: pointer;
}

.formButton button {
  font-size: 1em;
}

.date-picker-container {
  padding: 1em;
}

@media print {
  .horizontal-button-control {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .horizontal-button-control div {
    margin-left: 0.5em;
  }

  .formButton.hideInPrint.unselected {
      display: none;
  }
}

  /* Mobile Styles */
  @media only screen  and (max-width: 450px) {
    .horizontal-button-pouch {
      width: 100%;
    }
  }

  /* Mobile Styles */
  @media only screen and (max-width: 850px) {
    .horizontal-button-control.column {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: stretch;
        align-content: stretch;
    }
  
    .horizontal-button-control {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: stretch;
      align-content: stretch;
    }

    .horizontal-button-control div{
        margin-top: 0.2em;
        margin-left: 0.2em;
      }
  
    .horizontal-button-control.column div{
        margin-top: 0.2em;
    }

    .formButton button {
      font-size: 1em;
    }

    .date-picker-container {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      transform: scale(0.9);
      padding: 0;
      width: 100%;
    }

    

  }
  
   /* Tablet Styles */
  @media only screen and (min-width: 851px) and (max-width: 1300px) {

    .horizontal-button-control {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: stretch;
      align-content: stretch;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      //min-width: 15em;
    }
  
    .horizontal-button-control .margin {
      margin-left: 0.5em;
    }

    .button-fill {
      //min-width: 45%;
   }
  }
  
    /* Desktop Styles */
    @media only screen and (min-width: 1301px) {
  
      .horizontal-button-control {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: stretch;
        align-content: stretch;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        //min-width: 15em;
      }
  
      .horizontal-button-control .margin {
        margin-left: 0.5em;
      }

      .button-fill {
         //min-width: 50%;
      }
}

    