.content-view {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    align-content: stretch;
    white-space: pre-wrap;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .content-view-top-main-header-title {
    flex-direction: row;
    display:flex;
    align-items: center;

    .form-label {
      flex: 1;
    }
  }

  .content-view-top-main.subcell {
    padding-left: 0.5em;
    padding-right: 0.2em;
  }

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}

.content-view-top-main-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}

.content-view-top-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: stretch;

    
}



.content-view-top-main.padding {
  margin-right: 1em;
}

.form-label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-view-top-main-detail {
  //margin-left: 0.5em;
}

.content-view-validation.subcell {
  //margin-left: 0.5em;
}

.contentViewTop.withPadding {
  padding-bottom: 0.6em;
}

.content-view-top-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-detail-export-bar{
  margin-top: 1em;
  margin-right: 60%;
}

.callout-badge {
  width: 4px;
  height: 40px;
  background-color: #FFAB2A;
  border-radius: 3px;
  margin-right: 12px; 
}
  
  /* Mobile Styles */
  @media only screen and (max-width: 850px) {
    .contentViewTop {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: stretch;
      align-items:  stretch;
    }

    .contentViewTop.force-row{
      flex-direction: row;
    }
  
    .form-text-field {
      width: auto;
    }

    .form-long-text-field {
      width: auto;
    }
  
    .content-view-top-main {
      flex: 2em 2em 2em;
    }
  
    .content-view-top-main-header-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .content-view-top-main-header-title.empty {
      flex: 0;
    }

    .content-view.padding {
      padding-left: 0.8em;
      padding-right: 0.8em;
      padding-top: 0.6em;
      padding-bottom: 0.6em;
    }

    .content-view-subcell.subcell-padding {
      padding-left: 0.8em;
      padding-right: 0.8em;
      padding-top: 0.8em;
      padding-bottom: 0.8em;
    }

    .form-date-textfield {
      width: 100%;
    }

    .form-detail-export-bar{
      margin-top: 1em;
      margin-right: 0%;
    }
  }
  
   /* Tablet Styles */
  @media only screen and (min-width: 851px) and (max-width: 1300px) {
    .contentViewTop{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: stretch;
      align-items: stretch;
    }
  
    .form-text-field {
      width: 15em;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    .form-long-text-field {
      width: 26em;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  
    .content-view-top-main-header-title {
      flex: 2 2 2em;
    }
  
    .content-view-top-main{
      flex: 2 2 2em;
    }

    .content-view.padding {
      padding-left: 1.2em;
      padding-right: 1.2em;
      padding-top: 1em;
      padding-bottom: 1em;
      margin-left: 0.5em;
    }

    .content-view-subcell.subcell-padding {
      padding-left: 0.8em;
      padding-right: 0.8em;
      padding-top: 0.8em;
      padding-bottom: 0.8em;
    }
  }
  
    /* Desktop Styles */
    @media only screen and (min-width: 1301px) {
      .contentViewTop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: stretch;
        align-items: stretch;
      }
  
      .form-text-field {
        width: 15em;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }

      .form-long-text-field {
        width: 26em;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
      
      .content-view-top-main-header-title {
        flex: 2 2 2em;
      }
  
      .content-view-top-main{
        flex: 2 2 2em;
      }

      .content-view.padding {
        padding-left: 1.2em;
        padding-right: 1.2em;
        padding-top: 1em;
        padding-bottom: 1em;
        margin-left: 0.5em;
      }
  
      .content-view-subcell.subcell-padding {
        padding-left: 0.8em;
        padding-right: 0.8em;
        padding-top: 0.8em;
        padding-bottom: 0.8em;
      }
  }

  @media print { 
    .contentViewTop {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: stretch;
      align-items: stretch;
      padding-bottom: 0em;
    }

    .form-text-field {
      width: 15em;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    .form-long-text-field {
      width: 26em;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    .content-view-top-main-header-title {
      flex: 2 2 2em;
    }

    .content-view-top-main{
      flex: 2 2 2em;
    }

    .content-view.padding {
      padding-left: 1.2em;
      padding-right: 1.2em;
      padding-top: 1em;
      padding-bottom: 1em;
      margin-left: 0.5em;
    }

    .content-view-subcell.subcell-padding {
      padding-left: 0.8em;
      padding-right: 0.8em;
      padding-top: 0.8em;
      padding-bottom: 0.8em;
    }

  }