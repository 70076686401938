.notification-toast-container {
    position: fixed;
    z-index: 101;
    user-select: none;
    cursor: pointer;
    max-width: 400px;
    width: 90%;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
}

.notification-toast {
    background-color: #019CFF;
    border-radius: 15px;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    padding-left: 1.4em;
    padding-right: 1.4em;

    color: white;
    text-align: left;

    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    border-style: none;
    user-select: none;

    -webkit-box-shadow: -1px 4px 20px -1px rgba(0,0,0,0.15);
    -moz-box-shadow: -1px 4px 20px -1px rgba(0,0,0,0.15);
    box-shadow: -1px 4px 20px -1px rgba(0,0,0,0.15);
}

.notification-toast:hover {
    background-color: #17A4FF;
}

.notification-toast .notification-toast-close-button {
    display: none;
}

.notification-toast:hover .notification-toast-close-button {
    display: block;
}


.hide-toast {
    animation: hideToast .3s ease-out;
    opacity: 0;
    pointer-events: none;
}

.show-toast {
    animation: showToast .3s ease-out;
    opacity: 1;
}

.notification-toast-close-button{
    border-style: none;
    color: white;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
    margin-left: 0.5em;
    display: flex;
    flex-direction: column;
    padding-top: 0.2em;
    font-size: 0.6em;
    font-weight: 600;
    position: absolute;
    top: 0.9em;
    right: 0.6em;
}

.toast-results-icon {
    height: 40px;
    width: 40px;
    user-select: none;
}

.notification-toast-close-button:hover {
    background-color: rgba(0, 0, 0, 0.082);
    border-radius: 100px;
}

.toast-image-container {
    position: relative;
    display: inline-block;
    margin-right: 1em;
    user-select: none;
}

.toast-notification-badge{
    background-color: #FF2808;
    opacity: 1;
    border-radius: 100px;
    width: 0.8em;
    height: 0.8em;
    position: absolute;
    right: 0.1em;
    top: 0em;
    user-select: none;
}

/* Mobile Styles */
@media only screen and (max-width: 850px) {

    @keyframes hideToast {
        from {
            transform: translateY(0px);
            opacity: 1;
        }

        to {
            transform: translateY(60px);
            opacity: 0;
        }
    }
    
    @keyframes showToast {
        from {
            transform: translateY(60px);
            opacity: 0;
        }
        to {
            transform: translateY( 0px);
            opacity: 1;
        }
    }
}
  
  /* Tablet Styles */
  @media only screen and (min-width: 851px) and (max-width: 1300px) {

    @keyframes hideToast {
        from {
            transform: translateY(0px);
            opacity: 1;
          }
          to {
            transform: translateY(200px);
            opacity: 0;
          }
      }
    
      @keyframes showToast {
        from {
            transform: translateY(200px);
            opacity: 0;
          }
          to {
            transform: translateY( 0px);
            opacity: 1;
          }
      }
  }
  
  /* Desktop Styles */
  @media only screen and (min-width: 1301px) {

    .notification-toast-container {
        bottom: 4em;
    }

    @keyframes hideToast {
        from {
            transform: translateY(0px);
            opacity: 1;
          }
          to {
            transform: translateY(200px);
            opacity: 0;
          }
      }
    
      @keyframes showToast {
        from {
            transform: translateY(200px);
            opacity: 0;
          }
          to {
            transform: translateY( 0px);
            opacity: 1;
          }
      }
  }
