.modal-image-wrapper {
    border-width: 2px;
    border-color: white;
    border-style: solid;
    border-radius: 15px;
    background-color: white;
    overflow: scroll;
}

.modal-image-wrapper.portrait {
    max-width: 20%;
}

.modal-image-wrapper.landscape {
    max-width: 60%;
}

.modal-image {
    max-width: 90%;
    max-height: 90%;
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.modal-image.portrait {
    max-height: 80%;
}

.modal-image-exit-button-container {
    padding-right: 1em;
    margin-top: 1em;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.modal-image-exit-button-container .formButton {
    width: 3em;
    height: 3em;
}

.modal-image-exit {
    width: 2em;
    height: 2em;
}

.modal-image-link {
    color: #00A1F1 !important;
    cursor: pointer !important;
}
  
.modal-image-link:hover {
    text-decoration: underline !important;
}

@media only screen and (max-width: 850px) {

    .modal-image {
        max-width: 95%;
        max-height: 95%;
        margin-right: 2.5%;
        margin-left: 2.5%;
        margin-bottom: 2.5%;
    }
    
    .modal-image.portrait {
        max-height: 80%;
    }

    .modal-image-exit-button-container {
        padding-right: 1em;
        margin-top: 1em;
        margin-bottom: 0.2em;
    }
    
    .modal-image-exit-button-container .formButton {
        width: 0.5em;
        height: 0.5em;
    }

    .modal-image-wrapper.portrait {
        max-width: 70%;
        max-height: 95%;
    }
    
    .modal-image-wrapper.landscape {
        max-width: 95%;
    }

}