
.modal-overlay {
    opacity:              0.1;
    background-color:   black;
    position:             fixed;
    width:                100%;
    height:               100%;
    opacity:              0.5; 
    background:         #000; 
    width:                100%;
    height:               100%; 
    z-index:              106;
    top:                  0; 
    left:                 0; 
    position:             fixed; 
}


.modal-manager-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

    position:             fixed;
    width:                100%;
    height:               100%;
    width:                100%;
    height:               100%; 
    z-index:              108;
    top:                  0; 
    left:                 0; 
    position:             fixed; 
}

.modal-card {
    background-color: white;
    width: 30em;
    border-radius: 15px;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 0.8em;
    z-index:              107;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.modal-body {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
}

.modal-title {
    margin-left: 0.4em;
    margin-top: 0.5em;
    font-size: 2em;
    color: $default-text;
    font-weight: 700;
}

.button-wrapper {
    margin-left: 1em;
}

@media (prefers-color-scheme: dark) {
    .modal-title {
        color: $dark-default-text;
    }
}

/* Mobile Styles */
@media only screen and (max-width: 850px) {
    .modal-card{
        margin: 1em;
        
    }

    .modal-title {
        font-size: 1.6em;
    }

    .modal-card {
        background-color: white;
        width: 30em;
        border-radius: 15px;
        padding-left: 0.8em;
        padding-right: 0.8em;
        padding-bottom: 0.6em;
    }
  }
  
  /* Tablet Styles */
  @media only screen and (min-width: 851px) and (max-width: 1300px) {
  
  }
  
  /* Desktop Styles */
  @media only screen and (min-width: 1301px) {
  
  }